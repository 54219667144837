a {
    text-decoration: none;
}

p {
    margin: 0;
}

// overide antd reset.css
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
}