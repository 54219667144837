@font-face {
  font-family: 'Meirio';
  src: local('Meirio'), url('../../../public/fonts/Meiryo-regular.ttf'), format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Meirio';
  src: local('Meirio'), url('../../../public/fonts/Meiryo-Italic.ttf'), format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Meirio';
  src: local('Meirio'), url('../../../public/fonts/Meiryo-bold.ttf'), format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Meirio';
  src: local('Meirio'), url('../../../public/fonts/Meiryo-bold-Italic.ttf'), format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'yu-mincho-medium';
  src: local('yu-mincho-medium'), url('../../../public/fonts/yumin.ttf'), format('opentype');
  font-weight: medium;
  font-style: normal;
}

* {
  font-family: "Meirio", sans-serif;
  // font-style: normal;//GENKEN-755
}