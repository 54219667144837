@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500&display=swap');

@import "./variables.scss";
@import "./spacing.scss";
@import "./helper";
@import "./layout";
@import "./reset";
@import "./font";

html,
body {
    overscroll-behavior-y: none
}

html {
    scroll-behavior: smooth;
}

body {
    &.hidden-scroll::-webkit-scrollbar {
        display: none;
    }
}