//  define color
$backgroundColor: #f8f4e9;
$black: #020202;
$primaryColor: #9bb73f;
$red: #FF0000;
$blue: #47A0D0;
$white: #fff;
$grey: #C2C2C2;
$blueBold: #0000FF;

// define height
$headerHeight: 90px;
$footerHeight: 137px;
$headerHeightJounal: 100px;
$mobileHeaderHeightJounal: 70px;
$mobileHeaderHeight: 90px;
$mobileFooterHeight: 146px;

// sign-in screen variable
$mwSignInScreen: 1180px;