@import "@assets/styles/_variables.scss";

.app-container {
  max-width: 960px;
  margin: 0 auto;
}

.search-icon:hover {
  scale: 1.2;
  cursor: pointer;
}

.app {
  background-color: $backgroundColor;
  padding-top: $headerHeight;


  .app-layout {
    .app-header {
      padding: 9px 24px 9px 11px;
      max-width: 1580px;
      background-color: $backgroundColor;
      height: $headerHeight;
      position: fixed;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      z-index: 20;

      @media screen and (max-width: 1550px) {
        &.profile-book-seller {
          gap: 0;
        }

        .desktop-logo {
          width: 37%;
          margin-right: 0px;

          &>svg {
            width: 100%;
          }
        }

        .app-navigation {
          gap: 10px;

          .my-page-btn {
            margin-right: 0;
          }

          .search-input {
            max-width: 23vw !important;
          }

          .group-journal-icon {
            & .text {
              font-size: 18px;
            }
          }
        }
      }

      &.profile-book-seller {
        @media screen and (min-width: 993px) and (max-width: 1150px) {
          .app-navigation {
            gap: 5px;

            .nav-item {
              font-size: 15px;
              flex-shrink: 0;
              // white-space: nowrap;
            }

            .search-input {
              width: 220px !important;
            }

            .bird-icon {
              width: 180px;
            }
          }
        }
      }

      .mobile-logo {
        display: none;
      }

      .app-navigation {
        display: flex;
        flex-direction: row;
        gap: 25px;
        align-items: center;

        .bird-icon {
          max-width: 33px;
        }

        .bird-slogan-icon {
          width: 160px;
        }

        & .nav-item {
          white-space: nowrap;

          &.my-page-btn,
          &.border-btn {
            padding: 8px 17px;
            width: max-content;
            font-size: 18px;
            border: 2px solid #020202;
            border-radius: 7px;
          }
        }

        .nav-icon-item {
          display: none;
        }

        .user-icon {
          display: none;
        }

        .nav-item:hover {
          scale: 1.2;
          cursor: pointer;

          &.my-page-btn,
          &.border-btn {
            scale: unset;
          }
        }
      }
    }



    .app-content {
      min-height: calc(100vh - $headerHeight - $footerHeight);
    }
  }

  @media screen and (max-width: 576px) {
    padding-top: 0;

    .app-layout {
      .app-header {
        flex-direction: column;
        padding-left: 25px !important;
        padding-right: 25px !important;
        height: auto;
        position: unset;
      }

      .mobile-logo {
        width: 225px;
      }

      .user-icon {
        display: block !important;
      }

      .button-link-journal {
        height: 26.55px;
        width: 135px;
        border: 1px solid #707070;
        font-size: 15px;
        background-color: transparent;
        padding: 0;
      }
    }
  }

  .app-footer {
    height: $footerHeight;
    border-top: 3px solid $black;

    .version {
      position: absolute;
      bottom: 0;
      right: 50%;
      transform: translateX(50%);
    }

    @media screen and (max-width: 280px) {
      height: 200px !important;

      .footer-title {
        flex-wrap: nowrap !important;
        justify-content: space-between;

        a {
          margin: 0 !important;
        }
      }

      .version {
        position: absolute;
        bottom: 0;
        right: 50%;
        transform: translateX(50%);
      }

    }
  }

  .search-input {
    width: 430px;
    background-color: transparent;
    border-bottom: 3px solid $black;
    padding-bottom: 4px;
    position: relative;

    input {
      border: none;
      background-color: transparent;
      outline: none;
      flex: 1;

      &::placeholder {
        text-align: center;
      }
    }

    .search-icon:hover {
      cursor: pointer;
    }

  }
}

.custom-input {
  width: 100%;
  height: 60px;
  border-radius: unset;
  border-color: #e0e0e0;

  &::placeholder {
    font-size: 16px;
    line-height: 1;
  }
}

.custom-required-label {
  .ant-form-item-required {
    position: relative;

    &::before {
      content: "※必須" !important;
      font-size: 16px !important;
      position: absolute !important;
      right: -68px;
      font-family: 'Meirio' !important;
    }
  }
}

.app-jounal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .app-layout {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .header-wrap {
      background-color: $white;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1000;

      .app-header {
        max-width: 1580px;
        padding: 18px 90px;
        height: $headerHeightJounal;
        margin: auto;

        &.height-110 {
          height: 110px;
        }

        & .app-logo {
          object-fit: contain;
          object-position: 0 0;
        }

        .app-navigation {
          display: flex;
          flex-direction: row;
          gap: 47px;
          align-items: center;

          &.gap-20 {
            gap: 20px;
          }

          a {
            color: #020202 !important;
          }

          .nav-item {
            border: 2px solid #020202;
            border-radius: 7px;
            padding: 10px 20px;
            color: #020202 !important;

            &:hover {
              cursor: pointer;
            }

            &.my-page {
              padding: 8px 17px;
              width: 160px;
              text-align: center;
              font-size: 18px;
            }
          }

          .custom-search {
            padding: 10px 0 10px 0;
            border-bottom: 2px solid $black;

            .input {
              width: 250px;
              border: none;
              outline: none;
            }

            .search-icon {
              // margin-left: 10px;
            }
          }

          .book-seller-link {
            width: 100%;
            height: 100%;
            max-height: 60px;
            max-width: 250px;
            object-fit: contain;
            cursor: pointer;
          }

          .dashboard-nav {
            width: 100%;
            text-align: end;

            .book-link {
              background-color: #ccde90;
              padding: 3px;
              border: 1px solid #999;
              border-radius: 3px;
              color: $black;
              margin-left: auto;
              margin-bottom: 0;
              width: max-content;
              font-weight: 600;
              letter-spacing: 2px;
            }
          }
        }
      }
    }

    .app-sub-header {
      margin-top: $headerHeightJounal;
      background-color: $backgroundColor;
      width: 100%;

      .section-nav {
        max-width: 1280px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        gap: 30px;
        padding-top: 5px;
        padding-bottom: 5px;

        .nav-item {
          flex: 1;
          cursor: pointer;
          position: relative;

          &.active {
            &::after {
              content: "";
              width: 65%;
              height: 5px;
              background-color: #EF7862;
              bottom: 20px;
              right: 10px;
              position: absolute;
            }

            &.forum {
              &::after {
                width: 40%;
                bottom: 20px;
                right: 10%;
              }
            }
          }

          .image {
            width: 100%;
            height: 100px;
            object-fit: contain;

            &.desktop {
              display: block;

            }



            &.mobile {
              display: none;
            }
          }

          .title-mb {
            display: none;
            font-size: 16px;
          }
        }
      }
    }

    .app-content {
      min-height: calc(100vh - $headerHeightJounal - $footerHeight);
      margin-top: $headerHeightJounal;
      width: 100%;

      &.mt-0 {
        margin-top: 0 !important;
      }
    }
  }

  .app-footer {
    width: 100%;
    border-top: 3px solid #000000;
    padding-bottom: 20px;
  }

  @media screen and (max-width: 990px) {
    & .app-layout {

      & .header-wrap {

        & .app-header {
          & .app-navigation {
            gap: 0;

            & .book-seller-link {
              max-width: 50%;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1400px) {

    & .app-layout {

      & .header-wrap {

        & .app-header {
          padding: 18px 30px;

          & .app-logo {
            max-width: 20vw;
            object-fit: contain;
          }

          & .app-navigation {
            gap: 20px;
          }
        }
      }

      & .app-sub-header {

        & .section-nav {
          gap: 0px;

          & .nav-item {
            & .image {
              height: 80px;
            }
          }
        }
      }

    }

    & .app-footer {
      padding: 0 25px;
    }


  }

  @media all and (max-width: 480px) {
    .app-layout {
      .header-wrap {
        .app-header {
          padding: 0px 10px;
          height: $mobileHeaderHeightJounal;
          position: relative;

          .app-logo {
            max-width: 200px !important;
            object-fit: contain;
            margin-top: 5px;
          }

          .app-navigation {
            gap: 10px;
            justify-content: center;

            .nav-item {
              font-size: 11px;
              padding: 5px;
              border-radius: 5px;
            }

            .nav-search {
              position: absolute;
              top: 100%;
              right: 0;
              width: 100vw;
              background: $white;
              height: 50px;
              text-align: center;
            }

            .custom-search {
              display: flex;
              width: 75% !important;
              justify-content: end;
              margin-right: 10px;
              margin-left: auto;
              padding-top: 0;
            }
          }
        }
      }

      .app-sub-header {
        margin-top: calc($mobileHeaderHeightJounal + 50px);
        background-color: $white;
        width: 100%;
        padding: 0 10px;

        .section-nav {
          gap: 5px;

          .nav-item {
            &.active {
              &::after {
                display: none;
              }

              &.forum {
                &::after {
                  display: none;

                }
              }
            }

            .image {
              &.desktop {
                display: none;
              }

              &.mobile {
                display: block;
              }

            }

            & .title-mb {
              display: inline-block;
              text-align: center;
              width: 100%;
              font-size: 12px;
              font-weight: 700;

              &.active {
                border-bottom: 5px solid #EF7862;
              }
            }
          }
        }
      }

      .app-content {
        margin-top: $mobileHeaderHeightJounal;
        padding: 0;

        &.mt-120 {
          margin-top: 120px;
        }
      }
    }

    .app-footer {
      padding: 32px 34px;

      &>div {
        &:first-of-type {
          flex-wrap: wrap;
          justify-content: unset !important;
          gap: 16px 18px;
          margin-top: 0;

          & a {
            font-size: 11px;
            line-height: 11px;
          }
        }
      }

      & .copyright {
        font-size: 12px;
        margin-top: 23px;
      }
    }
  }
}

@media all and (max-width:1182px) {
  .nav-item {
    font-size: 15px;
  }

  .nav-item.nav-string {
    margin-right: 30px;
    white-space: nowrap;
  }
}

@media all and (max-width:992px) {
  .app {
    .app-layout {
      .app-header {
        padding: 9px 24px 9px 11px;

        .desktop-logo {
          display: none;
        }

        .mobile-logo {
          display: block;
        }

        .app-navigation {
          width: 100%;
          display: flex;
          flex-direction: row;
          gap: 13px;
          align-items: center;
          justify-content: flex-end;

          .cart-icon {
            width: 28px;
            height: 28px;
          }

          .user-icon {
            display: block;
          }

          .i-icon {
            display: none;
          }

          .search-input,
          .nav-item:not(:last-child):not(.cart-badge) {
            display: none;
          }

          .nav-icon-item {
            display: inline;
          }

          .info-icon {
            display: none;
          }

          .nav-item:hover {
            scale: 1.2;
            cursor: pointer;
          }
        }
      }
    }

    .app-footer {
      padding: 0px 34px;
      height: $mobileFooterHeight;

      &>div {
        flex-wrap: wrap;
        gap: 10px;
        width: 100%;
        justify-content: center;

        a {
          // &:nth-child(-n + 2) {
          //   width: 18%;
          // }

          // &:nth-child(5) {
          //   margin-right: 30px;
          // }

          font-size: 11px;
        }
      }

      &>div:last-child {
        font-size: 11px;
      }


    }

    .app-content {
      min-height: calc(100vh - $headerHeight - $mobileFooterHeight);
    }
  }

  .app-jounal {
    .app-layout {
      .header-wrap {
        .app-header {
          padding: 0px 15px;
          height: $mobileHeaderHeightJounal;
          position: relative;

          .app-logo {
            max-width: 40%;
            object-fit: contain;
            object-position: center;
          }

          .app-navigation {
            gap: 10px;
            justify-content: center;

            .nav-item {
              font-size: 11px;
              padding: 5px;
              border-radius: 5px;
            }

            .nav-search {
              position: absolute;
              top: 100%;
              right: 0;
              width: 100vw;
              background: $white;
              height: 50px;
              text-align: center;
            }

            .custom-search {
              display: flex;
              width: 50%;
              justify-content: center;
              margin-right: 15px;
              margin-left: auto;
              padding-top: 0;
            }
          }
        }
      }

      .app-sub-header {
        margin-top: calc($mobileHeaderHeightJounal + 50px);
        // background-color: $white;
        width: 100%;
        padding: 0 25px;

        .section-nav {
          gap: 5px;

          .nav-item {
            &.active {
              &::after {
                display: none;
              }

              &.forum {
                &::after {
                  display: none;

                }
              }
            }

            .image {
              &.desktop {
                display: none;
              }

              &.mobile {
                display: block;
              }

            }

            & .title-mb {
              display: inline-block;
              text-align: center;
              width: 100%;
              font-size: 16px;
              font-weight: 700;

              &.active {
                border-bottom: 5px solid #EF7862;
              }
            }
          }
        }
      }

      .app-content {
        margin-top: $mobileHeaderHeightJounal;
        padding: 0;

        &.mt-120 {
          margin-top: 120px;
        }
      }
    }

    .app-footer {
      padding: 32px 34px;

      &>div {
        &:first-of-type {
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 16px 18px;
          margin-top: 0;

          & a {
            font-size: 11px;
            line-height: 11px;
          }
        }
      }

      & .copyright {
        font-size: 12px;
        margin-top: 23px;
      }
    }
  }
}

@media all and (max-width: 480px) {
  .app {
    .app-layout {
      .app-header {
        padding: 9px 24px 9px 11px;

        .desktop-logo {
          display: none;
        }

        .mobile-logo {
          display: block;
        }

        .app-navigation {
          width: 100%;
          display: flex;
          flex-direction: row;
          gap: 13px;
          align-items: flex-end;
          justify-content: flex-end;

          .cart-icon {
            width: 28px;
            height: 28px;
          }

          .i-icon {
            display: none;
          }

          .search-input,
          .nav-item:not(:last-child):not(.cart-badge) {
            display: none;
          }

          .nav-icon-item {
            display: inline;
          }

          .info-icon {
            display: none;
          }

          .nav-item:hover {
            scale: 1.2;
            cursor: pointer;
          }
        }
      }
    }

    .app-footer {
      padding: 0px 34px;
      height: $mobileFooterHeight;

      & .copyright {
        font-size: 12px;
      }

      &>div {
        flex-wrap: wrap;
        gap: 12px;
        width: 100%;
        justify-content: flex-start;

        a {
          // &:nth-child(-n + 2) {
          //   width: 18%;
          // }

          // &:nth-child(5) {
          //   margin-right: 30px;
          // }

          font-size: 11px;
        }
      }

      &>div:last-child {
        font-size: 11px;
      }
    }

    .app-content {
      min-height: calc(100vh - $headerHeight - $mobileFooterHeight);
    }
  }

  .app-jounal {
    .app-layout {
      .app-sub-header .section-nav .nav-item {
        .title-mb {
          font-size: 12px;
        }
      }
    }
  }
}

.account-setting-drawer {
  background-color: transparent !important;

  & .ant-drawer-header {
    border-bottom: none;
  }

  & .account-setting-menu {
    width: 236px;
    background-color: #f8f4e9;
    border: 2px solid;
    margin: auto 0;
  }
}

.home-page-journal-layout {
  padding: 52.4px 124.9px 0 110.9px;

  .home-page-journal-container {
    border: 2px solid #020202;
    padding-bottom: 142px;

    .header {
      padding-top: 34.6px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 20px;
      margin-bottom: 63px;
      column-gap: 20px;

      .btn-mb-icon {
        display: none;
      }

      .header-btn {
        height: 35.7px;
        width: 102px;
        border-radius: 8px;
        border: 2px solid #020202;

        &.register {
          margin-right: 20.8px;
        }
      }

      &>img {
        width: 249px;
        height: 61px;
        margin-right: 20.6px;
      }
    }

    .header-search {
      border-bottom: 2px solid #020202;
      padding-bottom: 8px;
      display: flex;
      width: 305px;
      justify-content: center;

      input {
        width: 228px;
        border: none;
        outline: none;
        background-color: transparent;
      }

      & img {
        height: 26px;
        width: 26px;
        justify-self: flex-end;
        margin-left: 5px;
      }
    }

    .home-page-journal-heading {
      margin-bottom: 166px;

      .bird {
        object-fit: contain;
        width: 696px;
      }

      &>img:first-child {
        width: 721px;
        height: 142px;

      }

      &>img:last-child {
        width: 696px;
        height: 546px;
      }

      & div {
        font-size: 22px;
        line-height: 1.4;
        white-space: pre-line;
        max-width: 946px;
      }
    }

    .home-page-journal-option {
      & .category {
        &>img {
          // width: 100%;
        }

        &:first-child {
          margin-right: 10vw;
        }
      }

      & div {
        display: flex;
        flex-direction: column;
        align-items: center;



        div {
          font-size: 67px;
          // transform: translateY(-22px);
        }
      }
    }

    .home-page-journal-articles {
      margin: 0 64px;
      padding: 82px;
      border: 1px solid #000000;
      background-color: #faf5ed;

      & .articles-header {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-bottom: 140px;

        & .articles-search-wrapper {
          width: 40%;

          & .articles-search {
            margin-left: auto;
          }
        }

        & .text {
          position: absolute;
          margin-left: 50%;
          transform: translateX(-50%);
          font-size: 36px;
        }
      }

      & .articles-list {
        & .articles-item {
          &-desc {
            height: 60.2px;
            -webkit-line-clamp: 3;
            // text-align: justify;
          }
        }
      }
    }
  }

  @media screen and (max-width:1400px) {
    padding: 30px 40px 0;

    & .home-des {
      padding: 0 20px;
    }

    & .home-page-journal-container {
      padding-bottom: 40px;

      & .home-page-journal-option {
        & .category:first-child {
          margin-right: 3vw;
        }

        & .category {
          & img {
            width: 30vw;
            height: 30vh;
            object-fit: contain;
          }

          & .text-center {
            font-size: 45px;
          }
        }
      }

      & .home-page-journal-articles {
        margin: 0 3vw;
        padding: 3vw;

        & .articles-header {

          & .articles-search-wrapper {
            width: 30vw;

            & .articles-search {
              margin-left: auto;
            }
          }
        }

        & .articles-list {
          margin-left: -20px !important;
          margin-right: -20px !important;
          row-gap: 60px !important;

          &>.ant-col {
            padding-left: 20px !important;
            padding-right: 20px !important;
          }
        }
      }
    }
  }

  @media screen and (max-width:1024px) {
    padding: 30px 40px 0;

    & .home-des {
      padding: 0 20px;
    }

    & .home-page-journal-container {
      padding-bottom: 40px;

      & .header {
        flex-wrap: wrap;

        &>img {
          width: 220px;
          margin-right: 0;
        }
      }

      & .home-page-journal-heading {

        &>img:first-child {
          width: 80vw;
          height: 142px;
          object-fit: contain;
        }

        & .bird {
          max-width: 100%;
        }
      }

      & .home-page-journal-option {
        & .category:first-child {
          margin-right: 3vw;
        }

        & .category {
          & img {
            width: 40vw;
            height: 100%;
            object-fit: contain;
          }

          & .text-center {
            font-size: 30px;
          }
        }
      }

      & .home-page-journal-articles {
        margin: 0 3vw;
        padding: 3vw;

        & .articles-header {
          margin-bottom: 60px;


          & .articles-search-wrapper {
            width: 40vw;
            margin-top: 50px;

            & .articles-search {
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 576px) {
    padding: 13px 11.4px 48px;

    .home-page-journal-container {
      padding-bottom: 44px;

      .header {
        flex-direction: column;
        padding-top: 11px;
        padding-right: 21.4px;
        align-items: flex-end;

        .my-page-btn {
          display: none;
        }

        & .btn-wrapper {
          order: 0;

          & .header-btn {
            width: 58px;
            height: 28px;
            font-size: 11px;
            padding: 0;
          }

          & .btn-my-page {
            width: 65px;
          }


          & .header-btn:last-child {
            margin: 0;
          }
        }

        & .logo-icon {
          order: 1;
          display: none;
        }

        & .btn-mb-icon {
          display: block;
          margin: 0;
          border: 1px solid #707070;
          background-color: #ccde90;
          display: flex;
          justify-content: center;
          border-radius: 4px;
        }
      }

      & .header-search {
        width: 238px;
        padding-bottom: 7px;
        margin-top: 17px;
        margin-bottom: 20px;

        img {
          height: 22px;
          width: 22px;
        }

        input {
          font-size: 11px;
          width: 165px;
        }

        &.articles-search {
          margin: 0;
        }
      }

      .home-page-journal-heading {
        margin-bottom: 53px;

        &>img:first-child {
          width: 291px;
          height: 58px;
        }

        &>img.bird {
          width: 90%;
        }

        &>div {
          font-size: 15px;
          margin: 0 22.4px;
          line-height: 25px;
        }
      }

      .home-page-journal-option {
        padding: 0 13px;

        & .category {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 110px;

          &:first-child {
            margin-right: 17px;
          }

          img {
            width: 152px;
          }

          & .text-center {
            font-size: 26px;
            transform: translateY(0);
            white-space: nowrap;
            letter-spacing: -5px;
          }

          &:last-child {
            div {
              transform: translateY(6px);
            }
          }
        }
      }

      .home-page-journal-articles {
        margin: 0 13.6px;
        padding: 13.6px;

        & .articles-header {
          display: block;
          position: unset;
          height: auto;
          margin-bottom: 48px;

          & .text {
            transform: translateX(0);
            margin: 0;
            text-align: center;
            width: 100%;
            position: unset;
          }

          & .articles-search-wrapper {
            width: 60vw;
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;
            margin-left: auto;
          }
        }

        & .articles-list {
          row-gap: 58px !important;
          padding: 0 25px;

          & .articles-item {
            .articles-item-divide {
              margin-bottom: 17.5px;
            }

            &-desc {
              // height: 57.2px;
              -webkit-line-clamp: 3;
              margin-bottom: 0;
            }

            .articles-item-time {
              padding-top: 36px;

              span {
                text-align: right;
                margin-top: 0;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

.home-page-journal-footer {
  padding: 32px 34px;
  margin-top: 138px;
  border-top: 3px solid #000000;

  & .app-footer {

    & .footer-title {
      flex-wrap: wrap;
    }

  }

  @media screen and (max-width: 576px) {
    margin-top: 68px;

    & .app-footer {
      &>div {
        &:first-of-type {
          flex-wrap: wrap;
          justify-content: unset;
          gap: 16px 18px;
          margin-top: 0;

          & a {
            font-size: 11px;
            line-height: 11px;
          }
        }
      }

      & .copyright {
        font-size: 12px;
        margin-top: 23px;
      }
    }
  }
}

.genken-homepage-layout {
  background-color: #F8F4E9;
  position: relative;
  overflow-x: hidden;

  .genken-homepage-content {
    padding-top: 54px;
    padding-bottom: 54px;

    @media (min-width: 577px) and (max-width: 750px) {
      .nav-item {
        white-space: nowrap;
        font-size: 14px;
        margin-right: 10px !important;

      }

      .cart-icon {
        margin-right: 0 !important;
      }

      .search-input {
        width: 210px !important;
      }
    }

    @media (min-width: 577px) and (max-width: 610px) {
      .app-navigation {
        padding-left: 10px;
      }

      .nav-item {

        margin-right: 0 !important;

      }

      .search-input {
        width: 190px !important;
      }
    }

  }

  .line-left {
    position: absolute;
    top: 54px;
    left: 64px;
    bottom: 53px;
    border-left: 2px solid;
    background-color: #000000;
    z-index: 10;
  }

  .line-right {
    position: absolute;
    top: 54px;
    right: 64px;
    bottom: 53px;
    border-right: 2px solid;
    background-color: #000000;
    z-index: 10;
  }

  @media screen and (max-width: 576px) {
    .genken-homepage-content {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .line-left {
      position: absolute;
      top: 10px;
      left: 10px;
      bottom: 53px;
      background-color: #000000;
      z-index: 10;
    }

    .line-right {
      position: absolute;
      top: 10px;
      right: 10px;
      bottom: 53px;
      background-color: #000000;
      z-index: 10;
    }
  }

}

.genken-homepage-layout-footer {
  background-color: #F8F4E9;
  border-top: 2px solid #000000;
  padding-top: 32px;
  padding-bottom: 42px;

  & .app-footer {

    & .copyright {
      font-size: 17px;

      @media (max-width: 576px) {
        font-size: 12px;
      }
    }
  }

  & .app-footer>div:first-child {
    margin-top: 0;
    display: flex;
    flex-wrap: wrap;
    padding: 0 34px;
    gap: 16px 18px;
  }

  @media screen and (max-width: 576px) {


    & a {
      font-size: 11px;
      white-space: nowrap;


      // &:nth-child(5) {
      //   margin-left: 20px;
      // }

      // &:nth-child(6) {
      //   margin-right: 20px;
      // }
    }

    & .app-footer>div:first-child {
      justify-content: unset;
    }

  }


}

.custom-header-genken-homepage-layout {
  padding: 0 64px;

  & .app {
    padding-top: 0;
  }

  & .app-header {

    .app-header {
      border-top: 2px solid #000000;
      padding: 16px 0;
    }
  }

  & .desktop-logo {
    visibility: hidden;
    width: 1px;
    height: 0px;
  }

  & .mobile-logo {
    display: none;
  }

  & .app-navigation {
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    gap: 18px;
    align-items: center;

    .nav-icon-item {
      display: none;
    }

    .nav-item.nav-string {
      margin-right: 52px;

      @media screen and (min-width: 577px) and (max-width: 1180px) {
        margin-right: 32px;
      }

    }

    .user-icon {
      display: none;
    }

    .nav-item:hover {
      scale: 1.2;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 576px) {
    padding: 0 10px;
    margin-bottom: 10px;


    & .desktop-logo {
      width: 0;
    }

    & .app-navigation {
      padding-right: 20px;
      padding-top: 12px;
      gap: 11px;
    }

    & .search-icon {
      height: 22px;
      width: 22px;

    }

    & .nav-string {
      display: none;
    }

    .info-icon {
      display: none !important;
    }

    .user-icon {
      display: block !important;
    }

    & .search-input {
      width: 190px;
      margin-right: 53px;

      & input {
        width: 150px;
        font-size: 14px;
      }
    }

    & img.nav-item {
      width: 25px;
      height: 25px;
    }
  }

  .search-input {
    @media screen and (min-width: 577px) and (max-width: 992px) {
      width: 300px !important;
    }
  }
}

.custom-modal-Confirm-cancel-paid-member {
  & .ant-modal {
    & .ant-modal-content {
      & .ant-modal-body {
        & .des {
          &>p {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

.custom-modal-Confirm-cancel-paid-member,
.custom-modal-get-name-for-download-invoice {
  & .ant-modal {
    & .ant-modal-content {
      border-radius: 0;
      padding: 60px 66px 45px;

      & .ant-modal-header {
        & .ant-modal-title {
          text-align: center;
          font-weight: 400;
          font-size: 20px;
        }
      }

    }
  }

  .withdraw-button {
    border: 1px solid black;

    span {
      transform: translateY(2px);
    }
  }

  .continue-button {
    width: 233px;
    padding-left: 9px;
  }

  .confirm-widthdraw {
    width: 196px;
    margin-right: -15px;
  }

  @media screen and (max-width: 775px) {
    & .ant-modal {
      & .ant-modal-content {
        padding: 53px 35px 56px;

        & .ant-modal-body {
          & .des {
            margin-top: 19px;
            margin-bottom: 32px;
          }
        }

        & .ant-modal-footer {
          & .mt-mobile-15 {
            margin-top: 0px;
          }
        }
      }
    }

    .des-paid-member {
      span {
        display: block;
      }
    }

    .confirm-col {
      text-align: center;

      .confirm-widthdraw {
        margin-right: 0;
      }
    }

    .withdraw-button {
      width: 255px;
      height: 47px;
      font-size: 19px;
    }
  }
}

.custom-modal-get-name-for-download-invoice {
  & .ant-modal {
    & .ant-modal-content {
      padding: 123px 79px 82px;

      & .ant-modal-header {
        & .ant-modal-title {
          font-size: 24px;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    & .ant-modal {
      & .ant-modal-content {
        padding: 40px 25px;
      }
    }
  }
}

.ant-drawer-content-wrapper {
  box-shadow: none !important;
}

.confirm-register-membership {
  & .ant-modal-content {
    border-radius: 0;
    padding: 50px 89px 56px;

    & .description,
    & .more-description {
      p {
        margin-bottom: 0;
      }
    }

    & .more-description {
      max-width: 67%;
      margin-left: auto;
      margin-right: auto;
    }

    .button-group {

      & .modal-btn {
        height: 60px;
        font-size: 17px;
        border-radius: 0;
        color: white;
        width: 100%;

        &.cl-black {
          background-color: #313131;
        }
      }
    }

    @media screen and (max-width: 480px) {
      padding: 20px;
    }
  }
}