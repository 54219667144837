@import "./variables";

html.block-swipe-touch-pad-to-back,
html.block-swipe-touch-pad-to-back body {
  overscroll-behavior-x: none;

}

body {
  font-family: "Meirio", sans-serif !important;
  font-weight: 400;
  font-size: 16px;
}

.read-book-layout {
  background: rgb(248, 244, 233);
  overflow-y: hidden;
  position: relative;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  overscroll-behavior-x: none;

  .book-name-title {
    margin-bottom: 0 !important;
  }
}

.highlight-text-search {
  color: #9BB73F;
  font-weight: 500;
}

.ant-input[disabled] {
  color: #000000 !important
}

.logo-font {
  font-family: "senobi-gothic", sans-serif !important;
}

.ant-breadcrumb-link {
  a {
    color: #000000 !important;
  }
}