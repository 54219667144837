$space: 1501;

.object-cover {
  object-fit: cover;
}

.object-contain {
  object-fit: contain;
}

// custom

.ls-75 {
  letter-spacing: 0.75px;
}

.ls-25 {
  letter-spacing: 0.25px;
}

// display

$displays: (
  block,
  flex,
  inline,
  inline-flex,
  inline-block,
  grid
);
$justifyValue: (
  flex-start,
  flex-end,
  center,
  space-between,
  space-around,
  space-evenly
);
$alignValue: (
  flex-start,
  flex-end,
  center
);

.flex-1 {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-col {
  flex-direction: column;
}

@each $display in $displays {
  .#{$display} {
    display: $display;
  }
}

@each $value in $justifyValue {
  $index: str-index(quote($value), $substring: "-");

  @if $index {
    .justify-#{str-slice($value, $index + 1)} {
      justify-content: $value;
    }
  }

  @else {
    .justify-#{str-slice($value, 0)} {
      justify-content: $value;
    }
  }
}

@each $value in $alignValue {
  $index: str-index(quote($value), $substring: "-");

  @if $index {
    .items-#{str-slice($value, $index + 1)} {
      align-items: $value;
    }
  }

  @else {
    .items-#{str-slice($value, 0)} {
      align-items: $value;
    }
  }
}

//

// spacing
$places: (
  top,
  left,
  right,
  bottom
);
$types: (
  margin,
  padding
);
$directions: (
  x,
  y
);

@each $type in $types {
  @for $n from -$space to $space {
    .#{str-slice($type, 0, 1)}-#{$n} {
      #{$type}: #{$n}px;
    }

    @each $d in $directions {
      .#{str-slice($type, 0, 1)}#{$d}-#{$n} {
        @if $d ==x {
          #{$type}-left: #{$n}px;
          #{$type}-right: #{$n}px;
        }

        @else {
          #{$type}-top: #{$n}px;
          #{$type}-bottom: #{$n}px;
        }
      }
    }

    @each $place in $places {
      .#{str-slice($type, 0, 1)}#{str-slice($place, 0, 1)}-#{$n} {
        #{$type}-#{$place}: #{$n}px;
      }
    }
  }
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.decoration-none {
  text-decoration: none;
}

.underline {
  text-decoration: underline;
}

@each $place in $places {
  @for $n from -$space to $space +1 {
    .#{$place}-#{$n} {
      #{$place}: #{$n}px;
    }
  }
}

// Typography

@for $n from 1 to $space {
  .text-#{$n} {
    font-size: #{$n}px;
  }

  .leading-#{$n} {
    line-height: #{$n}px;
  }
}

$textAlignValue: (
  left,
  right,
  center,
  justify,
  initial,
  inherit
);

@each $value in $textAlignValue {
  .text-#{$value} {
    text-align: $value;
  }
}

$fontWeightValue: (
  100,
  200,
  300,
  400,
  500,
  600,
  700,
  800,
  900,
  bold
);

@each $value in $fontWeightValue {
  .font-#{$value} {
    font-weight: $value;
  }
}

// Sizing

$sizeType: (
  width,
  height
);
$sizePoint: (
  max,
  min
);

@each $value in $sizeType {
  .#{str-slice($value, 0, 1)}-full {
    #{$value}: 100%;
  }

  @each $sizePointValue in $sizePoint {
    .#{$sizePointValue}-#{str-slice($value, 0, 1)}-full {
      #{$sizePointValue}-#{$value}: 100%;
    }
  }
}

@for $n from -$space to $space +1 {
  @each $sizeTypeValue in $sizeType {
    .#{str-slice($sizeTypeValue, 0, 1)}-#{$n} {
      #{$sizeTypeValue}: #{$n}px;
    }

    @each $sizePointValue in $sizePoint {
      .#{$sizePointValue}-#{str-slice($sizeTypeValue, 0, 1)}-#{$n} {
        #{$sizePointValue}-#{$sizeTypeValue}: #{$n}px;
      }
    }
  }
}

// Borders

@for $n from 1 to $space {
  .rounded-#{$n} {
    border-radius: #{$n}px;
  }
}

// Transform
$translateDirection: (
  x,
  y
);

@for $n from -$space to $space {
  @each $d in $translateDirection {
    .translate-#{$d}-#{$n} {
      @if $d ==x {
        transform: translateX(#{$n}px);
      }

      @else {
        transform: translateY(#{$n}px);
      }
    }
  }
}

// Position

$positions: (
  absolute,
  relative,
  fixed,
  sticky
);

@each $position in $positions {
  .#{$position} {
    position: $position;
  }
}

// Cursor

$cursorValue: (
  pointer,
  default
);

@each $value in $cursorValue {
  .cursor-#{$value} {
    cursor: $value;
  }
}

//
.overflow-hidden {
  overflow: hidden;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// background
.bg-gray-light {
  background-color: #f5f5f5;
}

.bg-jounal-primary {
  background-color: #faf5ed;
}

// color
.cl-gray {
  color: #c2c2c2;
}

.cl-error {
  color: #ff0000;
}

.cl-black {
  color: #000000;
}

// border
.border-unset {
  border-radius: unset;
}

// common class
.submit-button {
  height: 60px;
  font-size: 15px !important;
}

.jo-submit-button {
  color: #fff;
  background-color: #47a0d0;
}

.ant-btn {
  &.danger {
    background-color: #E01907;
  }
}


.breadcrumb {
  font-size: 14px;

  a {
    text-decoration: none;
    color: #000000;
  }
}

.align-items-center {
  align-items: center;
}

$direction: (
  row,
  column
);

@each $value in $direction {
  .direction-#{$value} {
    flex-direction: $value;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.footer-read-book {
  height: 32px;
  width: 100px;
  outline: none;
  margin: 8px;
  color: rgb(134, 134, 134);
  border: 1px solid rgb(134, 134, 134);
  padding: 8px 4px
}

.move-button {
  height: 32px;
  width: 100px;
  outline: none;
  border-radius: unset;
}

.border-radius-unset {
  border-radius: unset;
}

.width-100 {
  width: 100%;
}

.white-space-wrap {
  white-space: pre-wrap;
}

.border-bottom-default {
  border-bottom: 1px solid #707070;
}

.border-default {
  border: 1px solid #c2c2c2;
}

.font-weight-bold {
  font-weight: bold;
}